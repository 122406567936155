<template>
<div class="order-detials-block">
  <v-card class="content" >
    <v-toolbar
        color="white"
        class="text--primary top-bar fixed-bar"
        flat
    >
      <v-btn class="transparent elevation-0 text--primary" v-on:click="closeDialog">
        <v-icon
            class="icon text--primary mr-3"
        >
          keyboard_arrow_left
        </v-icon>
        <span class="mt-1">Back to Orders</span>
      </v-btn>
    </v-toolbar>
    <v-row justify="center" v-if="isLoadingCurrentOrder">
      <v-col cols="12" align="center">
          <div>
            <v-img class="rotate" src="~@/assets/images/logo.svg" height="98px" width="75px" contain></v-img>
            <p class="text-h4 mt-3">
              Please wait...
            </p>
          </div>
      </v-col>
    </v-row>
    <v-row class="ml-10 mt-2" v-else>
      <v-col cols="12">
        <v-row>
          <v-col cols="7">
            <v-row>
              <v-col cols="12">
                <span class="orderId">{{ this.currentOrder.name }}</span>
                <v-chip class="ml-5" :class="getFinancialStatusClass()">
                  <v-icon class="chip-color">
                    {{ getFinancialStatusIcons() }}
                  </v-icon>
                  <span class="chip-font ml-2 mt-1">{{ getFinancialStatus() }}</span>
                </v-chip>
                <v-chip class="ml-5" :class="getPushStatusClass(this.currentOrder.push_status)">
                  <v-icon class="chip-icon-color">{{ getPushStatusIcons(this.currentOrder.push_status) }}</v-icon>
                  <span class="ml-2 mt-1 chip-font">{{ getPushStatus(this.currentOrder.push_status) }}</span>
                </v-chip>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col>
                <div class="order-reference-id">Store Order ID: <a target="_blank" :href="`https://${currentShop.store_domain}/admin/orders/${currentOrder.id}`">{{ this.currentOrder.id }}</a></div>
                <div class="mt-3 order-date">{{ this.currentOrder.created_at | moment('MMM DD Y') }} at {{ this.currentOrder.created_at | moment('hh:mm A') }}</div>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="mr-16">
            <div class="customer-details" v-if="
              currentOrder.customer != null && 
              !(currentOrder.customer.first_name === null && currentOrder.customer.last_name === null) &&
              !(currentOrder.customer.first_name === '' && currentOrder.customer.last_name === '')">
              <span class="font-weight-bold">Customer Name:</span><br>
              <span>{{ this.currentOrder.customer.first_name }} {{ this.currentOrder.customer.last_name }}</span><br><br>
            </div>
            <div class="customer-details" v-else>
              <span class="font-weight-bold">Customer Name:</span><br>
              <span class="red--text font-italic font-weight-bold">no customer name</span><br><br>
            </div>
            <div class="customer-details" v-if="currentOrder.shipping_address != null">
              <span class="font-weight-bold">Ship To:</span><br>
              <span v-if="currentOrder.shipping_address.address1">{{ this.currentOrder.shipping_address.address1 }} </span>
              <span v-if="currentOrder.shipping_address.address2"> {{ this.currentOrder.shipping_address.address2 }} </span>
              <span> {{ this.currentOrder.shipping_address.city }} {{ this.currentOrder.shipping_address.province }} </span>
              <span> {{ this.currentOrder.shipping_address.zip }} {{ this.currentOrder.shipping_address.country }} </span>
            </div>
            <div class="customer-details" v-else>
              <span class="font-weight-bold">Ship To:</span><br>
              <span class="red--text font-italic text-no-wrap font-weight-bold">no shipping address</span>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="currentOrder.customer === null || currentOrder.shipping_address === null">
          <v-col cols="12">
            <v-card class="customer-error-card rounded-0" width="698">
              <v-card-text class="pa-0">
                <div class="d-flex">
                  <v-icon class="ml-6" color="red">error</v-icon>
                  <div class="ml-4 black--text">
                    <v-card-title>Order does not contain customer name and/or shipping address.</v-card-title>
                    <v-card-subtitle class="black--text">To push this order, please duplicate this order with customer information, then cancel this order.  </v-card-subtitle>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col cols="12">
            <div class="order-contains-title">This order contains product(s) from {{ this.getNumberOfStore(this.currentOrder.source_stores) }}.</div>
            <div class="click-push-order-text">Click ‘Push Order’ to send order with synced product(s) to the source store. Price includes taxes.</div>
          </v-col>
        </v-row>
        <v-row class="mt-5" v-for="(storeOrder, storeName) in this.currentOrder.source_stores" :key="storeName" :set="v = $v.shippingCost.$each[storeName]">
          <v-col cols="12">
            <v-card class="store-item-card rounded pa-5" width="704px" color="#F8F8FA">
              <v-row>
                <v-col class="text-no-wrap">
                  <v-icon class="navy">store</v-icon><span class="store-name navy ml-2">{{ storeName }}</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col 
                  v-if="storeOrder.push_status != 'pushed'"
                  cols="4"
                >
                  <v-text-field
                    v-if="!(currentOrder.customer === null || currentOrder.shipping_address === null)"
                    light
                    outlined
                    dense
                    background-color="white"
                    class="shipping-cost-input pr-5 elevation-0"
                    v-model="shippingCost[storeName]"
                    :error-messages="updateShippingCostInputStatus(storeName)"
                    prefix="$"
                    @change="$v.$touch()"
                  >
                    <template v-slot:label>
                      <span style="font-size: 13px; margin-left: 3px;">
                        Enter a shipping fee
                      </span>
                    </template>
                  </v-text-field>
                  <div 
                    v-if="!(currentOrder.customer === null || currentOrder.shipping_address === null)"
                    class="divider"></div>
                </v-col>
                <v-col>
                    <v-btn
                      block
                      height="48px"
                      width="182px"
                      color="primary"
                      class="push-order-button transparent"
                      v-on:click="pushOrderToSourceStore(storeOrder.target_store_id, storeName)"
                      v-if="currentOrder.customer != null && currentOrder.shipping_address != null && storeOrder.push_status != 'pushed' && (v != null && !v.$invalid)"
                      :loading="storeOrder.push_status === 'pushing'"
                    >Push Order</v-btn>
                    <v-btn
                      block
                      height="48px"
                      width="182px"
                      class="pushed-button float-right transparent elevation-0 disable-events"
                      v-else-if="storeOrder.push_status === 'pushed'"
                    >
                        <v-icon color="success">
                          check_circle
                        </v-icon>
                        <span class="ml-2">Pushed</span>
                    </v-btn>
                    <v-btn
                      block
                      height="48px"
                      width="182px"
                      class="float-right elevation-0 disable-events disabled-push-button"
                      v-else
                    >Push Order</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="storeOrder.push_status === 'failed'">
                <v-col cols="1">
                  <v-icon color="#FF0000">
                    error
                  </v-icon>
                </v-col>
                <v-col class="ml-n5">
                  <span class="warning-text red--text font-weight-bold">Please check with the source store if this order has been pushed successfully. Note that pushing again may cause a duplicate order.</span>
                </v-col>
              </v-row>
              <v-row class="mt-7 product-details-color" v-for="lineItem in storeOrder.line_items" :key="lineItem.sku">
                <v-col cols="6">
                  <div class="d-flex">
                    <div class="product-image">
                      <v-img :src="lineItem.image" contain></v-img>
                    </div>
                  <div class="ml-3">
                    <span class="product-title">{{ lineItem.title }}</span><br>
                    <span class="product-sku">SKU: {{ lineItem.sku }}</span>
                  </div>
                  </div>
                </v-col>
                <v-col cols="3" style="text-align: right">
                  <span class="quntity">${{ parseFloat(lineItem.unit_price).toFixed(2) }} x {{ lineItem.quantity }}</span>
                </v-col>
                <v-col style="text-align: right">
                  <span class="quntity">${{ parseFloat(lineItem.total_price).toFixed(2) }}</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  ADD_NOTIFICATION,
  FETCH_CURRENT_ORDER,
  HIDE_SINGLE_ORDER, LAUNCH_STORE_ORDER_PUSHING_STATUS,
  PUSH_ORDER_TO_TARGET_STORE
} from "@/store/actions.type";
import _ from "lodash";
import { required, decimal, minValue } from 'vuelidate/lib/validators'

export default {
  name: "OrderDetailsBlock",
  data: function() {
    return {
      shippingCost: [],
    };
  },
  validations: {
    shippingCost: {
        $each: {
          required,
          decimal,
          minValue: minValue(0.0),
        }
    },
  },
  computed:{
    ...mapGetters("orders", ["showSingleOrder", "currentOrder", "isLoadingCurrentOrder"]),
    ...mapGetters("shop", ["currentShop"]),
  },
  methods:{
    updateShippingCostInputStatus (storeName) {
      const newShippingCostErrors = [];
      if (typeof this.shippingCost[storeName] === 'undefined' || !this.$v.shippingCost.$each[storeName].$dirty) {
        return newShippingCostErrors;
      }
      !this.$v.shippingCost.$each[storeName].required && newShippingCostErrors.push('Must be a valid number')
      !this.$v.shippingCost.$each[storeName].decimal && newShippingCostErrors.push('Must be a valid number')
      !this.$v.shippingCost.$each[storeName].minValue && newShippingCostErrors.push('Must be equal or bigger than zero')
      return newShippingCostErrors;
    },
    closeDialog(){
      this.$store.dispatch(`orders/${HIDE_SINGLE_ORDER}`)
    },
    getNumberOfStore(source_stores){
      return Object.keys(source_stores).length > 1 ? Object.keys(source_stores).length+" source stores" : Object.keys(source_stores).length+" source store";
    },
    pushOrderToSourceStore(targetStoreId, storeName){
      this.$v.$touch();
      if ( !this.$v.$invalid) {
        let newShippingCost  = this.shippingCost[storeName];
        this.$store.dispatch(`orders/${LAUNCH_STORE_ORDER_PUSHING_STATUS}`, targetStoreId)
        this.$store.dispatch(`orders/${PUSH_ORDER_TO_TARGET_STORE}`, {
          currentStoreId: this.currentShop.id, 
          currentOrderId: this.currentOrder.syncio_order_id, 
          targetStoreId: targetStoreId,
          shippingCost: newShippingCost
        }).then((data) => {
          if (data.success === true){
            this.displayOrderPushNotification("bottom-left", "success", data.message)
          }else{
            this.displayOrderPushNotification("bottom-left", "error", data.message)
          }
        });
      }
    },
    displayOrderPushNotification(position, status, message){
      this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
        notification: {
          id: 'N' + (Math.floor(Math.random() * 100000000)),
          position: position,
          type: status,
          body: message,
          state: 0,
          length: 6000, // seconds
          data: null,
        },
      });
    },
    getOrderPushedStatus(){
      const noOfTargetStores = Object.keys(this.currentOrder.source_stores).length;
      const allPushedStatus = Object.values(this.currentOrder.source_stores).map(value => value.push_status === "pushed" ? true : value.push_status === "failed" ? 'failed' : false );
      const countOfStatus = _.countBy(allPushedStatus);
      if (countOfStatus.true === noOfTargetStores){
        return "pushed";
      }else if(countOfStatus.false === noOfTargetStores){
        return "not pushed";
      }else if(countOfStatus.failed > 0 ){
        return "failed"
      }else{
        return "partial pushed"
      }
    },
    loadCurrentOrder(orderId){
      this.$store.dispatch(`orders/${FETCH_CURRENT_ORDER}`, {storeId: this.currentShop.id, orderId: orderId});
    },
    getFinancialStatusClass(){
      let financialStatus = this.currentOrder.financial_status;
      let financialStatusClasses = {
        'authorised': 'paid-chip',
        'pending': 'payment-pending-chip',
        'paid': 'paid-chip',
        'partially_paid': 'partially-paid-chip',
        'refunded': 'refunded-chip',
        'voided': 'refunded-chip',
        'partially_refunded': 'refunded-chip',
        'unpaid': 'not-paid-chip',
      }
      return financialStatusClasses[financialStatus];
    },
    getFinancialStatus(){
      let financialStatusFromShopify = this.currentOrder.financial_status;
      let financialStatus = {
        'authorised': 'Authorised',
        'pending': 'Pending',
        'paid': 'Paid',
        'partially_paid': 'Partially Paid',
        'refunded': 'Refunded',
        'voided': 'Voided',
        'partially_refunded': 'Partially Refunded',
        'unpaid': 'Unpaid',
      }
      return financialStatus[financialStatusFromShopify];
    },
    getFinancialStatusIcons(){
      let financialStatusFromShopify = this.currentOrder.financial_status;
      let financialStatusIcons = {
        'authorised': 'circle',
        'pending': 'trip_origin',
        'paid': 'circle',
        'partially_paid': 'tonality',
        'refunded': 'report_problem',
        'voided': 'report_problem',
        'partially_refunded': 'report_problem',
        'unpaid': 'trip_origin',
      }
      return financialStatusIcons[financialStatusFromShopify];
    },
    getPushStatusClass(pushStatus){
      let pushStatusClasses = {
        'not_pushed': 'not-push-chip',
        'pushed': 'push-chip',
        'failed': 'failed-chip',
        'invalid': 'failed-chip',
        'partially_pushed': 'partially-push-chip'
      }
      return pushStatusClasses[pushStatus];
    },
    getPushStatus(pushStatus){
      let pushStatusText = {
        'not_pushed': 'Not Pushed',
        'pushed': 'Pushed',
        'failed': 'Failed',
        'invalid': 'Invalid',
        'partially_pushed': 'Partially Pushed'
      }
      return pushStatusText[pushStatus];
    },
    getPushStatusIcons(pushStatus){
      let pushStatusIcons = {
        'not_pushed': 'trip_origin',
        'pushed': 'circle',
        'failed': 'report_problem',
        'invalid': 'report_problem',
        'partially_pushed': 'tonality'
      }
      return pushStatusIcons[pushStatus];
    },
  }
}
</script>
<style lang="scss" scoped>
.fixed-bar{
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0;
  z-index: 2;
}
.content{
  border-radius: 0px;
  position: fixed;
  overflow-y: scroll;
  background-color: #fff;
  color: #333;
  height: 100%;
  top: 0;
  right: 0;
  min-width: 800px;
  max-width: 1000px;
}
.orderId{
  font-style: normal;
  font-weight: normal;
  font-size: 18.16px;
  line-height: 25px;
}

.paid-chip{
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #ECFFEC !important;
  .chip-color{
    color: #28A228 !important;
    font-size: 8.33px;
  }
  .chip-font{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #085F07;
    text-transform: capitalize;
  }
}

.partially-paid-chip{
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #FFF2E3 !important;
  .chip-color{
    color: #AA5200 !important;
    font-size: 8.33px;
  }
  .chip-font{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #AA5200;
    text-transform: capitalize;
  }
}

.not-paid-chip{
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #F8F9FA !important;
  .chip-color{
    color: #495057 !important;
    font-size: 8.33px;
  }
  .chip-font{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #495057;
    text-transform: capitalize;
  }
}

.payment-pending-chip{
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #FCB058 !important;
  .chip-color{
    color: #495057 !important;
    font-size: 8.33px;
  }
  .chip-font{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #495057 !important;
    text-transform: capitalize;
  }
}

.refunded-chip{
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #FFECEC !important;
  .chip-color{
    color: #FF0000!important;
    font-size: 11px;
  }
  .chip-font{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #FF0000!important;
    text-transform: capitalize;
  }
}

.push-status-chip{
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  height: 24px;
}
.push-status-text{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #495057;
}
.order-reference-id{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.order-date{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.customer-details{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.order-contains-title{
  font-style: normal;
  font-weight: 500;
  font-size: 20.88px;
  line-height: 34px;
}
.click-push-order-text{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.navy{
  color: #0E3B4D;
}
.store-item-card{
  width: 704px;
  color: #F8F8FA;
}
.store-name{
  font-weight: 600;
  font-size: 18.16px;
  line-height: 25px;
}
.shipping-cost-input {
  float: right;
  margin-left: -4px;
  border-radius: 5px;
  width: 190px;
}

.divider {
  height: 50px;
  border-right: 1px solid #ccc;
  padding-left: 5px;
  display: block;
}
.push-order-button{
  float: left;
  border: 1px solid #ACB5BD;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #495057;
}
.pushed-button{
  border: 1px solid #ACB5BD;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #495057;
}
.product-details-color{
  color: #000000;
}
.product-image{
  height: 50px;
  width: 50px;
  border-radius: 50%;
    padding: 5px;
}
.product-title{
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}
.product-sku{
  font-size: 12px;
  line-height: 16px;
  color: #495057;
  margin-top: 8px;
}
.quntity{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
.disable-events {
  pointer-events: none
}
.warning-text{
  color: black;
}

.push-chip{
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #ECFFEC !important;
  .chip-icon-color{
    color: #28A228 !important;
    font-size: 8.33px;
  }
  .chip-font{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #085F07;
    text-transform: capitalize;
  }
}

.partially-push-chip{
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #FFF2E3 !important;
  .chip-icon-color{
    color: #AA5200 !important;
    font-size: 8.33px;
  }
  .chip-font{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #AA5200;
    text-transform: capitalize;
  }
}

.not-push-chip{
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #FCB058 !important;
  .chip-icon-color{
    color: #495057 !important;
    font-size: 8.33px;
  }
  .chip-font{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #495057;
    text-transform: capitalize;
  }
}

.failed-chip{
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #FFECEC !important;
  .chip-icon-color{
    color: #FF0000 !important;
    font-size: 11px;
  }
  .chip-font{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #FF0000;
    text-transform: capitalize;
  }
}

.disabled-push-button{
  background: #dcdcdc !important;
  color: #a8a8a8;
}

.customer-error-card{
  background-color: white;
  border-left: 12px solid red;
}
</style>
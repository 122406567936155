var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"order-management-page"},[(_vm.isLoadingOnPage)?_c('v-row',[_c('v-col',[_c('data-preparation-overlay')],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"title"},[_c('v-icon',{staticClass:"mt-n1",attrs:{"large":"","color":"orange"}},[_vm._v("move_to_inbox")]),_c('span',{staticClass:"text-h3 ml-5 text--primary"},[_vm._v("Orders")])],1)],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"small-body text-no-wrap"},[_vm._v("Push your orders with synced products to connected source stores ")])])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"mb-3 overflow-hidden push-setting-tools"},[_c('v-btn',{staticClass:"float-right push-setting-btn",attrs:{"color":"primary","dark":"","elevation":"0"},on:{"click":function($event){return _vm.redirectToPushOrderSettingsPage()}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-cog ")]),_c('span',{staticClass:"mt-1"},[_vm._v("Push Settings")])],1)]),_c('auto-push-switch-component')],1)])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"color":"white","rounded":""}},[_c('v-row',{staticClass:"mb-n10"},[_c('v-col',{staticClass:"mr-n10",attrs:{"cols":"1"}},[_c('div',{staticClass:"refresh-button"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{on:{"click":_vm.fetchOrders}},[_vm._v(" refresh ")])],1)]}}])},[_c('span',[_vm._v("Refresh Table")])])],1)]),_c('v-col',[_c('v-text-field',{staticClass:"search-bar",attrs:{"single-line":"","outlined":"","height":"36px","dense":"","disabled":_vm.isLoading,"append-icon":"clear"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchOrders($event)},"click:append":_vm.resetSearch},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"search-bar-label"},[_vm._v("Search by exact order number (e.g: #1234)")])]},proxy:true},{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mt-1",attrs:{"small":""}},[_vm._v("search")])]},proxy:true}]),model:{value:(_vm.searchStr),callback:function ($$v) {_vm.searchStr=$$v},expression:"searchStr"}})],1),_c('v-col',{staticClass:"ml-n10"},[_c('v-select',{staticClass:"sort-bar",attrs:{"outlined":"","single-line":"","dense":"","items":_vm.sortByItems,"item-text":"name","item-value":"value","menu-props":{ bottom: true, offsetY: true },"disable-lookup":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"sort-label",domProps:{"textContent":_vm._s("Sort")}})]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"size":"21px","color":"#000000"}},[_vm._v(" sort ")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"sortRadioButtons"},[_c('v-radio-group',{attrs:{"hide-details":"true"},on:{"change":_vm.fetchOrders},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}},[_c('v-radio',{attrs:{"value":item.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [(item.value === _vm.sortBy)?_c('div',{staticClass:"selected-sort-text",domProps:{"textContent":_vm._s(item.name)}}):_c('div',{domProps:{"textContent":_vm._s(item.name)}})]},proxy:true}],null,true)})],1)],1)]}},{key:"selection",fn:function(){return [_c('div',{staticClass:"sort-label",domProps:{"textContent":_vm._s("Sort")}})]},proxy:true}]),model:{value:(_vm.defaultSorting),callback:function ($$v) {_vm.defaultSorting=$$v},expression:"defaultSorting"}})],1)],1),_c('v-data-table',{staticClass:"mt-1 elevation-1 order-table row-hover",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.orders,"item-class":"row-hover","hide-default-footer":"","item-key":"id","items-per-page":_vm.getTotalOrderCounts()},on:{"click:row":_vm.loadCurrentOrder},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.created_at",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.customer_name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.push_status",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.line_items",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.id",fn:function(ref){return [_c('div',{staticClass:"header-text"})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-text"},[_vm._v(_vm._s(item.name))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-text"},[_vm._v(_vm._s(_vm._f("moment")(item.created_at,'MMM DD'))+" at "+_vm._s(_vm._f("moment")(item.created_at,'hh:mm A')))])]}},{key:"item.customer_name",fn:function(ref){
var item = ref.item;
return [(item.customer_name === null)?_c('div',{staticClass:"table-text red--text font-italic font-weight-bold"},[_vm._v(" no customer ")]):(item.customer_name.match(/^\s*$/) != null)?_c('div',{staticClass:"table-text red--text font-italic font-weight-bold"},[_vm._v("no customer")]):_c('div',{staticClass:"table-text"},[_vm._v(_vm._s(item.customer_name))])]}},{key:"item.push_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-text"},[_c('v-chip',{staticClass:"align-center justify-center",class:_vm.getPushStatusClass(item.push_status)},[_c('v-icon',{staticClass:"chip-icon-color"},[_vm._v(" "+_vm._s(_vm.getPushStatusIcons(item.push_status))+" ")]),_c('span',{staticClass:"ml-2 mt-1 chip-font"},[_vm._v(_vm._s(_vm.getPushStatus(item.push_status)))])],1)],1)]}},{key:"item.line_items",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-text"},[_vm._v(_vm._s(_vm.getItemCount(item.line_items)))])]}},{key:"item.id",fn:function(ref){return [_c('div',{staticClass:"table-text on-view-order-button ml-15"},[_c('span',[_vm._v("View Order")]),_c('v-icon',{staticClass:"ml-3"},[_vm._v("chevron_right")])],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-16 mb-16"},[_c('v-icon',{attrs:{"size":"80px","color":"#939393"}},[_vm._v("search")]),(_vm.searchStr === '' && _vm.getTotalOrderCounts() === 0)?_c('div',[_c('div',{staticClass:"no-order-big-text",domProps:{"textContent":_vm._s("There are no orders yet.")}}),_c('div',{staticClass:"no-order-small-text",domProps:{"textContent":_vm._s("Start by creating an order on Shopify or try to refresh the page.")}})]):_c('div',[_c('div',{staticClass:"no-order-big-text",domProps:{"textContent":_vm._s("There are no orders to see.")}}),_c('div',{staticClass:"no-order-small-text",domProps:{"textContent":_vm._s("Try changing your search term. We only display orders up to 60 days.")}})])],1)]},proxy:true}])})],1)],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('lazy-loading',{attrs:{"store":"orders","collection":"orders","search-str":_vm.searchStr,"sort-by":_vm.sortBy,"number-of-items":_vm.getTotalOrderCounts()}})],1)],1)],1)],1),(this.showSingleOrder)?_c('v-row',[_c('v-overlay',[_c('v-scroll-x-reverse-transition',[_c('order-details-block')],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
<v-container class="order-management-page">
    <v-row v-if="isLoadingOnPage">
      <v-col>
        <data-preparation-overlay></data-preparation-overlay>
      </v-col>
    </v-row>
    <v-row v-else>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col class="title">
                  <v-icon large color="orange" class="mt-n1">move_to_inbox</v-icon>
                  <span class="text-h3 ml-5 text--primary">Orders</span>
                </v-col>
              </v-row>
              <v-row justify="space-between">
                <v-col cols="6">
                  <p class="small-body text-no-wrap">Push your orders with synced products to connected source stores
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col>
                  <div class="mb-3 overflow-hidden push-setting-tools">
                    <v-btn
                        color="primary"
                        dark
                        elevation="0"
                        class="float-right push-setting-btn"
                        @click="redirectToPushOrderSettingsPage()"

                    >
                      <div class="d-flex align-center">
                        <v-icon
                            class="mr-2"
                        >
                          mdi-cog
                        </v-icon>
                        <span class="mt-1">Push Settings</span>
                      </div>
                    </v-btn>
                    <auto-push-switch-component></auto-push-switch-component>

                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
            <v-row>
              <v-col>
                <v-card color="white" rounded>
                  <v-row class="mb-n10">
                    <v-col class="mr-n10" cols="1">

                      <div class="refresh-button">
                        <v-tooltip
                            bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                              <v-icon v-on:click="fetchOrders">
                                refresh
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Refresh Table</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col>
                      <v-text-field
                          v-model="searchStr"
                          class="search-bar"
                          single-line
                          outlined
                          height="36px"
                          dense
                          v-on:keyup.enter="fetchOrders"
                          :disabled="isLoading"
                          append-icon="clear"
                          @click:append="resetSearch"
                      >
                        <template v-slot:label>
                          <span class="search-bar-label">Search by exact order number (e.g: #1234)</span>
                        </template>
                        <template v-slot:prepend-inner>
                          <v-icon small class="mt-1">search</v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="ml-n10">
                      <v-select
                          v-model="defaultSorting"
                          outlined
                          single-line
                          class="sort-bar"
                          dense
                          :items="sortByItems"
                          item-text="name"
                          item-value="value"
                          :menu-props="{ bottom: true, offsetY: true }"
                          disable-lookup
                      >
                      <template v-slot:label >
                        <div v-text="`Sort`" class="sort-label"></div>
                      </template>
                        <template v-slot:append>
                          <v-icon size="21px" color="#000000">
                            sort
                          </v-icon>
                        </template>
                          <template v-slot:item="{ item }">
                            <div class="sortRadioButtons">
                              <v-radio-group v-model="sortBy" v-on:change="fetchOrders" hide-details="true">
                                <v-radio :value="item.value">
                                  <template v-slot:label>
                                    <div v-text="item.name" v-if="item.value === sortBy" class="selected-sort-text"></div>
                                    <div v-text="item.name" v-else></div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </div>
                          </template>
                        <template v-slot:selection>
                          <div v-text="`Sort`" class="sort-label"></div>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-data-table
                      :loading="isLoading"
                      :headers="headers"
                      :items="orders"
                      item-class="row-hover"
                      class="mt-1 elevation-1 order-table row-hover"
                      hide-default-footer
                      item-key="id"
                      :items-per-page=getTotalOrderCounts()
                      v-on:click:row="loadCurrentOrder"
                  >
                    <template v-slot:header.name="{ header }">
                      <div class="header-text">{{ header.text }}</div>
                    </template>

                    <template v-slot:header.created_at="{ header }">
                      <div class="header-text">{{ header.text }}</div>
                    </template>

                    <template v-slot:header.customer_name="{ header }">
                      <div class="header-text">{{ header.text }}</div>
                    </template>

                    <template v-slot:header.push_status="{ header }">
                      <div class="header-text">{{ header.text }}</div>
                    </template>

                    <template v-slot:header.line_items="{ header }">
                      <div class="header-text">{{ header.text }}</div>
                    </template>

                    <template v-slot:header.id="{  }">
                      <div class="header-text"></div>
                    </template>


                    <template v-slot:item.name="{ item }">
                      <div class="table-text">{{item.name}}</div>
                    </template>

                    <template v-slot:item.created_at="{ item }">
                      <div class="table-text">{{ item.created_at | moment('MMM DD') }} at {{ item.created_at | moment('hh:mm A') }}</div>
                    </template>

                    <template v-slot:item.customer_name="{ item }">
                      <div class="table-text red--text font-italic font-weight-bold" v-if="item.customer_name === null"> no customer </div>
                      <div class="table-text red--text font-italic font-weight-bold" v-else-if="item.customer_name.match(/^\s*$/) != null">no customer</div>
                      <div class="table-text" v-else>{{ item.customer_name }}</div>
                    </template>

                    <template v-slot:item.push_status="{ item }">
                      <div class="table-text"> <v-chip class="align-center justify-center" :class="getPushStatusClass(item.push_status)">
                        <v-icon class="chip-icon-color"> {{ getPushStatusIcons(item.push_status) }} </v-icon>
                        <span class="ml-2 mt-1 chip-font">{{ getPushStatus(item.push_status) }}</span>
                      </v-chip></div>
                    </template>

                    <template v-slot:item.line_items="{ item }">
                      <div class="table-text">{{ getItemCount(item.line_items) }}</div>
                    </template>

                    <template v-slot:item.id="{  }">
                      <div class="table-text on-view-order-button ml-15"><span>View Order</span><v-icon class="ml-3">chevron_right</v-icon></div>
                    </template>

                    <template v-slot:no-data>
                      <div class="mt-16 mb-16">
                        <v-icon size="80px"
                          color="#939393"
                        >search</v-icon>
                        <div v-if="searchStr === '' && getTotalOrderCounts() === 0">
                          <div v-text="`There are no orders yet.`" class="no-order-big-text"></div>
                          <div v-text="`Start by creating an order on Shopify or try to refresh the page.`" class="no-order-small-text"></div>
                        </div>
                        <div v-else>
                          <div v-text="`There are no orders to see.`" class="no-order-big-text"></div>
                          <div v-text="`Try changing your search term. We only display orders up to 60 days.`" class="no-order-small-text"></div>
                        </div>
                      </div>
                    </template>

                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          <v-row class="mt-2">
            <v-col>
             <lazy-loading
                 store="orders"
                 collection="orders"
                 :search-str="searchStr"
                 :sort-by="sortBy"
                 :number-of-items="getTotalOrderCounts()"
             >
             </lazy-loading>
            </v-col>
          </v-row>
        </v-col>
    </v-row>
  <v-row v-if="this.showSingleOrder">
    <v-overlay>
      <v-scroll-x-reverse-transition>
        <order-details-block>
        </order-details-block>
      </v-scroll-x-reverse-transition>
    </v-overlay>
  </v-row>
</v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {FETCH_CURRENT_ORDER, FETCH_ORDERS} from "@/store/actions.type";
import DataPreparationOverlay from "@/views/components/DataPreparationOverlay";
import OrderDetailsBlock from "@/views/order/components/OrderDetailsBlock";
import AutoPushSwitchComponent from "@/views/order/components/AutoPushSwitchComponent";
import LazyLoading from "@/views/order/components/LazyLoading";


export default {
  name: "OrderManagementPage",
  components: {
    LazyLoading,
    OrderDetailsBlock,
    DataPreparationOverlay,
    AutoPushSwitchComponent
  },
  data: () => ({
    isLoadingOnPage: false,
    searchStr: '',
    sortBy: 'DESC',
      headers: [
        { text: "Order", align: "start", value: "name", sortable: false, width: "2%"},
        { text: "Date", value: "created_at", sortable: false, width: "20%" },
        { text: "Customer", value: "customer_name", sortable: false, width: "20%" },
        { text: "Push Status", value: "push_status", sortable: false, width: "16%" },
        { text: "Synced Items", value: "line_items", sortable: false, width: "15%" },
        { text: "Actions", value: "id", sortable: false },
      ],
    defaultSorting: { name: 'Date: Newest First', value: 'DESC' },
    sortByItems: [{ name: 'Date: Newest First', value: 'DESC' }, { name: 'Date: Oldest First', value: 'ASC' }],
  }),
  computed: {
    ...mapGetters("orders", ["orders", "isLoading", "currentOrder", "showSingleOrder"]),
    ...mapGetters("shop", ["currentShop"]),
  },
  created() {
      this.isLoadingOnPage = true;
      this.$store.dispatch(`shop/loadCurrentShop`).then((data) => {
        if (data.success === true){
          this.isLoadingOnPage = false;
          this.fetchOrders();
        }
      }).catch(() => {
        this.isLoadingOnPage = false;
      });
  },
  methods: {
    getTotalOrderCounts(){
      if (this.orders != undefined)
      {
        return this.orders.length;
      }
      return  0;
    },
    getItemCount(items){
      return items.length > 1 ? items.length + ' items' : items.length + ' item';
    },
    resetSearch(){
      this.searchStr = '';
      this.fetchOrders();
    },
    fetchOrders() {
      let modifiedSearchString = this.searchStr.replace('#', '--SYN--');
        this.$store
            .dispatch(`orders/${FETCH_ORDERS}`, {currentShopId: this.currentShop.id , searchStr: modifiedSearchString, sortBy: this.sortBy, pageNumber: 1}).then(() => {
        });
    },
    loadCurrentOrder(item){
      this.$store.dispatch(`orders/${FETCH_CURRENT_ORDER}`, {storeId: this.currentShop.id, orderId: item.id});
    },
    redirectToPushOrderSettingsPage() {
      this.$router.push({ name: "PushOrderSettingsPage" })
    },
    getPushStatusClass(pushStatus){
      let pushStatusClasses = {
        'not_pushed': 'not-push-chip',
        'pushed': 'push-chip',
        'failed': 'failed-chip',
        'invalid': 'failed-chip',
        'partially_pushed': 'partially-push-chip'
      }
      return pushStatusClasses[pushStatus];
    },
    getPushStatus(pushStatus){
      let pushStatusText = {
        'not_pushed': 'Not Pushed',
        'pushed': 'Pushed',
        'failed': 'Failed',
        'invalid': 'Invalid',
        'partially_pushed': 'Partially Pushed'
      }
      return pushStatusText[pushStatus];
    },
    getPushStatusIcons(pushStatus){
      let pushStatusIcons = {
        'not_pushed': 'trip_origin',
        'pushed': 'circle',
        'failed': 'report_problem',
        'invalid': 'report_problem',
        'partially_pushed': 'tonality'
      }
      return pushStatusIcons[pushStatus];
    }
  }
}
</script>
<style lang="scss" scoped>
.order-management-page{
  max-width: 1100px;
}
.title{
  display: flex;
    vertical-align: middle;
}
.medium-body-text{
  font-size: 1rem;
  font-weight: 400;
  line-height: 18.9px;
}
th{
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: left;
  color: #0E3B4D;
  text-transform: uppercase;
}
.header-text{
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: left;
  color: #0E3B4D;
  text-transform: uppercase;
}
.table-text{
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #000;
}
.status-font-style{
  vertical-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #495057;
  margin: 0px 3px;
}
.push-order-button{
  border: 1px solid #495057;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 4px 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.push-order-button:hover{
  border: 1px solid #495057;
  background-color: #495057;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 4px 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: white;
}
.refresh-button{
  padding: 8px 16px;
}
.search-bar{
  padding: 8px 16px;
  width: 609px;
  border-radius: 10px;
}
.search-bar-label{
  font-size: 13px;
  line-height: 18px;
  margin: 0px 20px;

}

.push-setting-tools {
  min-width: 430px;
  .push-setting-btn {
    height: 39px;
  }
}
.no-order-big-text{
  font-weight: 600;
  font-size: 24.88px;
  line-height: 34px;
  color: #000000 !important;
  margin-top: 9px;
}
.no-order-small-text{
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  padding: 10px;
  color: #000000 !important;
}
.sort-bar{
  padding: 8px 12px;
  border-radius: 10px;
  width: 100px;
}
.sort-label{
  color: #495057;
  font-size: 13px;
  font-weight: 600;
}
.sortRadioButtons{
  width: 201px;
  height: 50px;
  margin-top: -5px !important;
}
.selected-sort-text{
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #495057;
}

::v-deep .v-list-item--active::before{
  background-color: #ffffff;
}

.push-chip{
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #ECFFEC !important;
  vertical-align: center;
  .chip-icon-color{
    color: #28A228 !important;
    font-size: 8.33px;
  }
  .chip-font{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #085F07;
    text-transform: capitalize;
  }
}

.partially-push-chip{
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #FFF2E3 !important;
  .chip-icon-color{
    color: #AA5200 !important;
    font-size: 8.33px;
  }
  .chip-font{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #AA5200;
    text-transform: capitalize;
  }
}

.not-push-chip{
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #FCB058 !important;
  .chip-icon-color{
    color: #495057 !important;
    font-size: 8.33px;
  }
  .chip-font{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #495057;
    text-transform: capitalize;
  }
}

.failed-chip {
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #FFECEC !important;

  .chip-icon-color {
    color: #FF0000 !important;
    font-size: 11px;
  }

  .chip-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #FF0000;
    text-transform: capitalize;
  }
}

.row-hover{
  cursor: pointer;
  table{
    tr{
      &:hover{
        td{
          .on-view-order-button{
            visibility: visible;
          }
        }
      }
      td{
        .on-view-order-button{
          visibility: hidden;
        }
      }
    }
  }
}


</style>